import { render, staticRenderFns } from "./kubevirt.io.virtualmachine.vue?vue&type=template&id=fd6faedc&scoped=true&"
import script from "./kubevirt.io.virtualmachine.vue?vue&type=script&lang=js&"
export * from "./kubevirt.io.virtualmachine.vue?vue&type=script&lang=js&"
import style0 from "./kubevirt.io.virtualmachine.vue?vue&type=style&index=0&id=fd6faedc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6faedc",
  null
  
)

export default component.exports