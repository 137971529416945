
export default {
  props: {
    color: {
      type:    String,
      default: 'secondary'
    },
    label: {
      type:    [String, Error, Object],
      default: null,
    },
    labelKey: {
      type:    String,
      default: null,
    },
    closable: {
      type:    Boolean,
      default: false,
    }
  }
};
